import React, { useRef } from "react";
import PropTypes from "prop-types";

import "./Slider.scss";

const SlSlider = ({
  min,
  max,
  value,
  handleValueChange,
  step,
  isDisabled,
  ...inputControlEvents
}) => {
  const inputRef = useRef();
  return (
    <input
      className="sl-slider"
      type="range"
      min={min}
      max={max}
      ref={inputRef}
      step={step}
      value={value}
      disabled={isDisabled}
      onChange={(e) => {
        handleValueChange(e);
      }}
      onClick={() => inputRef.current.focus()}
      {...inputControlEvents}
    />
  );
};

SlSlider.propTypes = {
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
  step: PropTypes.number.isRequired,
  isDisabled: PropTypes.bool,
};

export { SlSlider };
