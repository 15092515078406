import React, { useState } from "react";
import { DeleteTextX, SearchIcon } from "../ActionButton/icons";
import PropTypes from "prop-types";

import "./styledInput.scss";

const StyledInput = ({ placeHolderText, searchAction, onDeleteText }) => {
  const [inputValue, setInputValue] = useState("");
  const [showDelete, setShowDelete] = useState(false);
  function handleInputChange(ev) {
    const value = ev.target.value;
    if (value === "") {
      handleDeleteText();
    }
    setInputValue(value);
    searchAction(value);
    setShowDelete(value.length > 0);
  }

  function handleDeleteText() {
    const value = "";
    searchAction(value);
    setInputValue(value);
    setShowDelete(false);
    onDeleteText();
  }

  return (
    <div className="Sl-styled-input">
      <div className="Sl-styled-input-icon">
        <SearchIcon />
      </div>
      <input
        onChange={(ev) => {
          handleInputChange(ev);
        }}
        placeholder={placeHolderText}
        id="Sl-styled-input-input"
        type="text"
        value={inputValue}
        className="Sl-styled-input-input"
      />
      {showDelete ? (
        <div
          onClick={() => {
            handleDeleteText();
          }}
          className="delete-x"
        >
          <DeleteTextX />
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

StyledInput.propTypes = {
  placeHolderText: PropTypes.string.isRequired,
  searchAction: PropTypes.func.isRequired,
};

export default StyledInput;
