import React, { useState, useRef } from "react";

import { ScatterplotLayer } from "@deck.gl/layers";
import DeckGL from "@deck.gl/react";

import "./Map.scss";
import ReactMapGL from "react-map-gl";
import * as d3 from "d3";
import { ZoomInOutHome } from "../ZoomInOutHome";
import { useMap } from "./map-hook";
import { clusterAlertMapMarkers } from "./Map-utils";

const MAX_RADIUS = 500;
const calculateRadius = d3
  .scaleSqrt()
  .domain([1, 10])
  .range([MAX_RADIUS / 4, MAX_RADIUS]);

const SlMap = (props) => {
  const nominalBasedMap = () => props?.data?.some((d) => d.nominal);

  const [tooltip, setTooltip] = useState({});

  const displayTooltip = (info) => setTooltip(info);

  const mapRef = useRef();

  const layer = new ScatterplotLayer({
    id: "scatterplot-layer",
    data: props.data ? clusterAlertMapMarkers(props.data) : null,
    pickable: true,
    opacity: nominalBasedMap() ? 0.4 : 1,
    stroked: true,
    filled: true,
    radiusScale: 10,
    radiusMinPixels: 2,
    radiusMaxPixels: 60,
    lineWidthMinPixels: 1,
    getPosition: (d) => [d?.long, d?.lat],
    getRadius: (d) => (d.nominal ? calculateRadius(d.nominal) : 2.5),
    getFillColor: () => [250, 20, 120],
    getLineColor: () => [0, 0, 0],
  });

  const { viewport, onViewportChange } = useMap({
    latitude: props.lat,
    longitude: props.long,
    zoom: props.zoom,
    transitionDuration: 300,
    width: 400,
    height: 400,
    markers: props.data,
  });

  function handleZoomIn() {
    document.activeElement.blur();
    onViewportChange({
      ...viewport,
      ...{ zoom: viewport.zoom + 1 },
    });
  }

  function handleZoomOut() {
    document.activeElement.blur();
    const newZoom = viewport.zoom < 1 ? 0 : viewport.zoom - 1;
    onViewportChange({
      ...viewport,
      ...{ zoom: newZoom },
    });
  }

  function handleResetViewport() {
    document.activeElement.blur();
    onViewportChange({
      ...viewport,
      ...{ reset: true },
    });
  }

  return (
    <div className="sl-map">
      {props.children}
      <div
        className={`sl-map__tooltip
          ${tooltip.enabled ? " sl-map__tooltip--active" : ""}`}
        style={{
          left: tooltip?.x,
          top: tooltip?.y,
          width: "fit-content",
        }}
      >
        {props.tooltip ? props.tooltip(tooltip) : null}
      </div>
      <ReactMapGL
        {...viewport}
        onViewportChange={onViewportChange}
        width={"100%"}
        height={"100%"}
        scrollZoom={true}
        doubleClickZoom={true}
        dragRotate={false}
        minZoom={0}
        ref={mapRef}
        mapStyle={
          "https://tiles.stadiamaps.com/styles/alidade_smooth_dark.json"
        }
      >
        <DeckGL
          viewState={{ ...viewport }}
          layers={[layer]}
          getCursor={({ isHovering }) => (isHovering ? "pointer" : "grab")}
          onClick={(info) => {
            if (info.picked) {
              displayTooltip({ ...info, ...{ enabled: true } });
            } else {
              displayTooltip({ ...info, ...{ enabled: false } });
            }
          }}
        />
      </ReactMapGL>
      <div className={"sl-map__controls-container"}>
        <ZoomInOutHome
          zoomInAction={handleZoomIn}
          zoomOutAction={handleZoomOut}
          handleHomeClick={handleResetViewport}
        />
      </div>
    </div>
  );
};

export { SlMap };
