import React from "react";
import PropTypes from "prop-types";
import { SlButton } from "../Button/Button";
import { SlIcon } from "../Icon/Icon";

import ExpandIcon from "../../../../../assets/svg/icon-expand.svg";
import ShrinkIcon from "../../../../../assets/svg/icon-collapse.svg";
import cx from "classnames";

import "./expandMap.scss";

const ExpandMap = ({ handleExpandMap, expanded }) => {
  const iconProps = expanded
    ? {
        svgFile: ShrinkIcon,
      }
    : {
        svgFile: ExpandIcon,
      };
  const classes = cx("sl-expand-map-wrapper-wrapper", {
    "sl-expand-map-wrapper-wrapper--expanded": Boolean(expanded),
  });
  return (
    <div className={classes}>
      <div className="sl-expand-map-wrapper">
        <SlButton
          round
          onClick={(ev) => {
            ev.stopPropagation();
            if (document.activeElement instanceof HTMLElement)
              document.activeElement.blur();
            handleExpandMap();
          }}
        >
          <SlIcon {...iconProps} />
        </SlButton>
      </div>
    </div>
  );
};

ExpandMap.propTypes = {
  handleExpandMap: PropTypes.func.isRequired,
  expanded: PropTypes.bool,
};

export { ExpandMap };
