import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import MapComp from "./Map";

import {
  getMonitorMapData,
  getMonitorSparkLineMapData,
} from "../../../../actions/monitorActions";

import "./map.scss";

import { findGeoPoints, clusterMonitorMapMarkers } from "./helpers";
import { SlPanelLoader } from "../../communal/Atoms/PanelLoader/PanelLoader";
import { center } from "../../../helpers/geo-center";
import { DEFAULT_WORLD_VIEW } from "../../../../utils/constants";
import FormattedMessage from "../../../../translations/FormattedMessage";

function MapAll({ data, getMapData, getSparkLineData, isError, isLoading }) {
  const [centerLocation, setCenterLocation] = useState(null);
  const [startZoom, setStartZoom] = useState(null);
  useEffect(() => {
    getMapData();
    getSparkLineData();
  }, [getMapData, getSparkLineData]);

  useEffect(() => {
    if (!isLoading) {
      const geo_data = data.partner;
      const [isPoints, longestKey] = findGeoPoints(geo_data);

      if (isPoints) {
        const sample =
          geo_data["offlineNetwork"].troublePoints < 2
            ? geo_data[longestKey].troublePoints
            : geo_data["offlineNetwork"].troublePoints;
        const sample_filtered = sample.filter((s) => s.geo.lat != null);
        const center_comp = center(sample_filtered, true);
        setCenterLocation(center_comp);
        setStartZoom(4);
      } else {
        setCenterLocation([DEFAULT_WORLD_VIEW.lat, DEFAULT_WORLD_VIEW.long]);
        setStartZoom(DEFAULT_WORLD_VIEW.zoom);
      }
    }
  }, [isLoading, data]);

  if (isLoading || centerLocation === null) {
    return (
      <div className="monitor-map-all">
        <SlPanelLoader />
      </div>
    );
  }

  if (isError) {
    return (
      <div className="monitor-map-all">
        <div className="Sl-monitor-loader">
          <FormattedMessage id="error" />
          <span className="Sl-err-ref">
            <FormattedMessage id="refreshPage" />
          </span>
        </div>
      </div>
    );
  }

  return (
    <div className="monitor-map-all">
      <MapComp
        data={clusterMonitorMapMarkers(data)}
        centerLocation={centerLocation}
        startZoom={startZoom}
      />
    </div>
  );
}

const mapStateToProps = (state) => ({
  isLoading: state.monitor.mapData_loading,
  isError: state.monitor.mapData_error,
  data: state.monitor.mapData,
});

const mapDispatchToProps = (dispatch) => ({
  getMapData: () => {
    dispatch(getMonitorMapData());
  },
  getSparkLineData: () => {
    dispatch(getMonitorSparkLineMapData());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(MapAll);
