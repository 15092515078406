import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import PlumeSelectionMenu from "../../../communal/Molecules/PlumeSelectionMenu/connected/PlumeSelectionMenu";
import SelectList from "../../../communal/Organisms/SelectList";
import { layersOnSelector } from "../../../../../selectors";
import { useToggleByClickOutside } from "../../../../../hooks/useToggleByClickOutside";

import { format as d3Format } from "d3-format";

import "./layers.scss";

export const styleSelector = {
  "Evil Twin": { backgroundColor: `rgb(23, 227, 174)` },
  "Offline Network": { backgroundColor: `rgb(250, 20, 120)` },
  "CSP Outage": { backgroundColor: `rgb(255, 197, 0)` },
  "Unstable Network": { backgroundColor: `rgb(98, 105, 255)` },
};

export function Layers({ selectionLength }) {
  const [showLayerSelector, setShowLayerSelector] = useState(false);
  const timer = useRef();

  function handleMenuClick() {
    setShowLayerSelector(!showLayerSelector);
  }

  const clickOutsideRef = useToggleByClickOutside({
    toggler: () => setShowLayerSelector(false),
    state: showLayerSelector,
  });

  const setTimerToCloseShowLayerSelector = () => {
    timer.current = setTimeout(() => {
      setShowLayerSelector(false);
    }, 4000);
  };

  useEffect(() => {
    if (showLayerSelector) {
      setTimerToCloseShowLayerSelector();
    }
  }, [showLayerSelector]);

  const bottomStyleOffset = selectionLength * 72;

  const wrapper_style = {
    top: `calc(100% - ${50 + bottomStyleOffset}px`,
    left: "0px",
  };

  return (
    <div style={wrapper_style} className="monitor-map-layers-rel">
      {showLayerSelector ? (
        <div
          className="sl-map-select-list"
          ref={clickOutsideRef.ref}
          onMouseEnter={(event) => {
            clearTimeout(timer.current);
          }}
          onMouseLeave={(event) => {
            setTimerToCloseShowLayerSelector();
          }}
        >
          <SelectList formatNumber={d3Format(",")} />
        </div>
      ) : null}
      <PlumeSelectionMenu menuOpenClose={handleMenuClick} />
    </div>
  );
}

const mapStateToProps = (state) => ({
  selectionLength: layersOnSelector(state).length,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Layers);
