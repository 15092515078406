import React, { useState } from "react";
import { connect } from "react-redux";
import AlertConfigPanel from "../../Organisms/AlertConfigPanel";

import "./alertTitleAndChannelPannel.scss";
import { SlText } from "../../../communal/Atoms/Text/Text";
import { useTranslation } from "react-i18next";
import FormattedMessage from "../../../../../translations/FormattedMessage";
import cx from "classnames";

export const AlertTitleAndChannelPannel = ({
  alertOnOff,
  alertThreshold,
  titleId,
  channels,
  channelThrottleChange,
  descriptionId,
  handleThresholdChange,
  id,
  enabled,
  isEditingDisabled,
}) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  const handleOnClickOpen = () => {
    setOpen(!open);
  };

  const handleToggleChange = () => {
    alertOnOff(id);
  };

  const openOnlyIfClosed = () => {
    if (open) {
      return;
    }
    handleOnClickOpen();
  };

  const rootClassNames = cx("sl-admin-alerts-config", {
    "sl-admin-alerts-config--closed": !open,
  });

  return (
    <div className={rootClassNames} onClick={openOnlyIfClosed}>
      <div className="sl-admin-alerts-config-title">
        <span onClick={handleOnClickOpen}>
          <SlText subtitle2m>
            <FormattedMessage id={titleId} />
          </SlText>
        </span>
      </div>
      {open && (
        <AlertConfigPanel
          alertThreshold={alertThreshold}
          channels={channels}
          enabled={enabled}
          alertID={id}
          description={t(descriptionId)}
          handleToggleChange={handleToggleChange}
          channelThrottleChange={channelThrottleChange}
          handleThresholdChange={handleThresholdChange}
          isEditingDisabled={isEditingDisabled}
        />
      )}
    </div>
  );
};

const mapDispatchToProps = () => ({});

export default connect(
  undefined,
  mapDispatchToProps
)(AlertTitleAndChannelPannel);
