import { isNull } from "lodash";

const findGeoPoints = (data) => {
  let noPoints = true;
  let longestKey = "";
  let largestLength = 0;

  Object.keys(data).forEach((key) => {
    if (data[key].troublePoints.length > 0) noPoints = false;
    if (data[key].troublePoints.length > largestLength) {
      longestKey = key;
      largestLength = data[key].troublePoints.length;
    }
  });

  if (noPoints) {
    return [false, ""];
  }

  return [true, longestKey];
};

const clusterMonitorMapMarkers = ({ partner }) => {
  Object.keys(partner).map((layerType) => {
    if (!partner[layerType].troublePoints.length) {
      return;
    }
    if (partner[layerType].troublePoints[0].idCluster) {
      return;
    }
    let res = [];
    partner[layerType].troublePoints
      .sort((a, b) => (a.geo.lat > b.geo.lat ? 1 : -1))
      .filter((el) => !isNull(el.geo.lat))
      .forEach((currEl, index, elements) => {
        let nextEl = elements[index + 1];
        currEl.idCluster = currEl.idCluster
          ? [...currEl.idCluster, currEl.id]
          : [currEl.id];
        if (
          currEl.geo.lat !== nextEl?.geo.lat ||
          currEl.geo.long !== nextEl?.geo.long ||
          nextEl === undefined
        ) {
          delete currEl.id;
          res.push(currEl);
        } else {
          nextEl.idCluster = [...currEl.idCluster];
        }
      });
    partner[layerType].troublePoints = res;
  });
  return { partner };
};

export { findGeoPoints, clusterMonitorMapMarkers };
