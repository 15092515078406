import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import cloneDeep from "lodash.clonedeep";

import "./Dropdown.scss";

import { SlMenu } from "../Menu/Menu";
import { SlSelectionList } from "../SelectionList/SelectionList";
import { useToggleByClickOutside } from "../../../../../hooks/useToggleByClickOutside";

const SlDropdown = (props) => {
  const [menuIsActive, setMenuIsActive] = useState(false);
  const [_selections, _setSelections] = useState(cloneDeep(props.selections));
  const triggerRef = useRef(null);
  const clickOutsideRef = useToggleByClickOutside({
    toggler: () => closeMenu(),
    state: menuIsActive,
  });

  useEffect(() => {
    if (menuIsActive && typeof props.autoFocusEl !== "undefined") {
      props.autoFocusEl.current.focus();
    }
  }, [menuIsActive, props.autoFocusEl]);

  const handleSelectOption = (option, section) => {
    closeMenu();

    Object.keys(_selections[section].options).forEach((key) => {
      _selections[section].options[key] = false;
    });

    _selections[section].options[option] = !_selections[section].options[
      option
    ];

    _setSelections(_selections);

    props.onSelect(option, section);
  };

  function closeMenu() {
    setMenuIsActive(false);
    if (props.onNewActiveState) {
      props.onNewActiveState(false);
    }
  }

  const handleTriggerClick = () => {
    setMenuIsActive(!menuIsActive);
    if (props.onNewActiveState) {
      props.onNewActiveState(!menuIsActive);
    }
  };

  function onClickChild(event) {
    if (!props.preventDismissOnClick) {
      if (
        event.target.tagName !== "INPUT" &&
        event.target.tagName !== "SPAN" &&
        event.target.tagName !== "svg" &&
        event.target.tagName !== "path" &&
        event.target.className !== "sl-item-content" &&
        event.target.className !== "sl-item hasFocus"
      ) {
        closeMenu();
      }
    }
  }

  const dropdown = () => {
    if (props.hasOwnProperty("selections")) {
      return (
        <SlSelectionList
          selections={props.selections}
          optionClicked={(option, section) =>
            handleSelectOption(option, section)
          }
          closeList={closeMenu}
        />
      );
    } else {
      return (
        <SlMenu size={props.size}>
          {React.Children.map(props.children, (child) => (
            <span onClick={(event) => onClickChild(event)}>{child}</span>
          ))}
        </SlMenu>
      );
    }
  };

  const classNames = classnames(
    "sl-dropdown",
    `${menuIsActive ? "sl-dropdown--active" : ""}`,
    `${
      props.align && props.align === "right"
        ? "sl-dropdown--align-right"
        : "sl-dropdown--align-left"
    }`
  );

  return (
    <div tabIndex="0" className={classNames} ref={clickOutsideRef.ref}>
      <div
        ref={triggerRef}
        className="sl-dropdown-trigger"
        onClick={handleTriggerClick}
        onHover={handleTriggerClick}
      >
        {props.trigger}
      </div>
      {dropdown()}
    </div>
  );
};

SlDropdown.propTypes = {
  size: PropTypes.string,
  align: PropTypes.string,
  selections: PropTypes.object,
  onSelect: PropTypes.func,
  trigger: PropTypes.element.isRequired,
  autoFocusEl: PropTypes.element,
  onNewActiveState: PropTypes.func,
  preventDismissOnClick: PropTypes.bool,
};

export { SlDropdown };
