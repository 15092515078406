import React, { useEffect, useRef, useCallback } from "react";
import { connect } from "react-redux";

import { SlIcon } from "../../communal/Atoms/Icon/Icon";
import { SlTooltip } from "../../communal/Atoms/Tooltip/Tooltip";

import ClipboardJS from "clipboard";
import Copy from "../../../../assets/icons/Copy.svg";

import "./styles/copyID.scss";

import { GET_NOC_URL } from "../../../../utils/constants";

function useOutsideAlerter(ref, onClick) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        onClick();
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, onClick]);
}

const CopyID = ({ close, x, y, link, idCluster, primaryDeployment }) => {
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, close);

  const copyRef = useCallback((node) => {
    if (node !== null) {
      new ClipboardJS(".sl-map-copy-icon");
    }
  }, []);

  const left = x > window.innerWidth - 270 ? window.innerWidth - 270 : x;
  const top = y - 40;

  const noclink = (id) => `${GET_NOC_URL(primaryDeployment)}/${id}`;

  const linkString = (id) => {
    return link ? (
      <a
        href={noclink(id)}
        target="_blank"
        rel="noreferrer"
        className="text-link"
      >
        {id}{" "}
      </a>
    ) : (
      <span style={{ color: "white" }}>{id}</span>
    );
  };

  return (
    <div ref={wrapperRef} className="map-pop-up-click-wrapper">
      <div
        ref={wrapperRef}
        className="map-pop-up-click"
        style={{ left: `${left}px`, top: `${top}px` }}
      >
        {idCluster?.length ? (
          idCluster.map((singleId) => (
            <div key={singleId}>
              <span className="text-to-copy"> {linkString(singleId)} </span>
              <span
                ref={copyRef}
                data-clipboard-text={singleId}
                className="sl-map-copy-icon"
              >
                {" "}
                <SlTooltip
                  interaction={"click"}
                  title={"Copied to clipboard."}
                  onClick={copyRef}
                  durationMSecs={2000}
                >
                  <SlIcon svgFile={Copy} />{" "}
                </SlTooltip>
              </span>
            </div>
          ))
        ) : (
          <div>
            <span className="text-to-copy"></span>
            <span
              ref={copyRef}
              data-clipboard-text={""}
              className="sl-map-copy-icon"
            >
              {" "}
              <SlTooltip
                interaction={"click"}
                title={"Copied to clipboard."}
                onClick={copyRef}
                durationMSecs={2000}
              >
                <SlIcon svgFile={Copy} />{" "}
              </SlTooltip>
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  partnerId: state.me.selectedPartner.idCluster,
  primaryDeployment: state.me.selectedPartner.primaryDeployment,
});

export default connect(mapStateToProps, undefined)(CopyID);
