import React, { useState, useEffect, useMemo } from "react";
import { ScatterplotLayer } from "@deck.gl/layers";
import { ClusterLayer } from "../../communal/Atoms/Map/custom-layers/cluster-layer";
import { connect } from "react-redux";
import { debounce } from "debounce";
import DeckCont from "./DeckCont";
import ReactMapGL from "react-map-gl";
import { timeFormat } from "d3-time-format";

import { ZoomInOutHome } from "../../communal/Atoms/ZoomInOutHome";
import { TitleUpdate } from "../../communal/Atoms/TitleUpdate";
import { ExpandMap } from "../../communal/Atoms/ExpandMap";
import { ExpandLeft } from "../../communal/Atoms/ExpandLeft";

import Layers from "./Layers";

import CopyID from "./CopyID";
import { useTranslation } from "react-i18next";

export const layerHashMap = {
  "High Congestion": "highCongestion",
  "Offline Network": "offlineNetwork",
  "Poor Coverage": "poorCoverage",
  "Unstable Network": "unstableNetwork",
  "CSP Outage": "ispOutage",
  "Evil Twin": "evilTwin",
};
export const pointStyles = {
  "Evil Twin": {
    filled: true,
    fill: [23, 227, 174],
    fillbehind: [23, 232, 174],
    line: [0, 0, 0],
    lineWidth: 1,
  },
  "Offline Network": {
    filled: true,
    fill: [250, 20, 120],
    fillbehind: [250, 25, 120],
    line: [0, 0, 0],
    lineWidth: 1,
  },
  "CSP Outage": {
    filled: true,
    fill: [255, 197, 0],
    fillbehind: [255, 197, 0],
    line: [0, 0, 0],
    lineWidth: 1,
  },
  "Unstable Network": {
    filled: true,
    fill: [98, 105, 255],
    fillbehind: [98, 110, 255],
    line: [0, 0, 0],
    lineWidth: 1,
  },
};

function MapComp({ centerLocation, startZoom, data, layers, ISPid, mapTime }) {
  const [layersOff] = useState(false);
  const [mapExpanded, setMapExpanded] = useState(false);
  const [showTime, setShowTime] = useState(false);
  const [toolTipLocation, setToolTipLocation] = useState({ x: 0, y: 0 });
  const [, setLayerName] = useState(null);
  const [viewportState, setViewportState] = useState({
    width: "100%",
    height: "100%",
    latitude: centerLocation[0],
    longitude: centerLocation[1],
    zoom: startZoom,
  });
  const [messageClicked, setMessageClicked] = useState({ id: -1 });
  const [, setSelectPoint] = useState(-1);
  const [hoveredObjectClicked, setHoveredObjectClicked] = useState(false);
  const { t } = useTranslation();

  const timeFormatted = useMemo(() => timeFormat("%H:%M%p")(mapTime), [
    mapTime,
  ]);

  function expandCollapseMap() {
    setMapExpanded(!mapExpanded);
    setViewportState({
      ...viewportState,
      ...{
        width: "100%",
        height: "100%",
      },
    });
  }

  function handleShowTime() {
    setShowTime(!showTime);
  }

  function handleZoomIn() {
    document.activeElement.blur();
    const new_zoom = viewportState.zoom + 1;
    setViewportState({
      ...viewportState,
      ...{ zoom: new_zoom },
    });
  }

  function handleZoomOut() {
    document.activeElement.blur();
    const new_zoom = viewportState.zoom < 1 ? 0 : viewportState.zoom - 1;
    setViewportState({
      ...viewportState,
      ...{ zoom: new_zoom },
    });
  }

  function handleResetViewport() {
    document.activeElement.blur();
    setViewportState({
      width: "100%",
      height: "100%",
      latitude: centerLocation[0],
      longitude: centerLocation[1],
      transitionDuration: 300,
      zoom: startZoom,
    });
  }

  useEffect(() => {
    setViewportState({
      width: "100%",
      height: "100%",
      latitude: centerLocation[0],
      longitude: centerLocation[1],
      transitionDuration: 300,
      zoom: startZoom,
    });

    return () => {
      setViewportState({
        width: "100%",
        height: "100%",
        latitude: centerLocation[0],
        longitude: centerLocation[1],
        transitionDuration: 300,
        zoom: startZoom,
      });
    };
  }, [centerLocation, startZoom]);

  function resizeMap() {
    setViewportState({ ...viewportState });
  }

  function handleSetViewPort(viewport) {
    setViewportState({ ...viewport });
  }

  useEffect(() => {
    const debounceResize = debounce(resizeMap, 100);
    window.addEventListener("resize", debounceResize);

    return function cleanup() {
      window.removeEventListener("resize", debounceResize);
    };
    // eslint-disable-next-line
  }, []);
  function closeIDCopy() {
    setHoveredObjectClicked(false);
    setMessageClicked({ id: -1 });
    setSelectPoint(-1);
  }

  function selectPointData(data, index) {
    for (let i = 0; i < data.length; i++) {
      if (i === index) {
        data[i].selected = data[i].selected ? false : true;
      } else {
        data[i].selected = false;
      }
    }
  }

  function renderOnClick() {
    const link = true;
    return (
      hoveredObjectClicked && (
        <CopyID
          idCluster={messageClicked.idCluster}
          close={closeIDCopy}
          {...toolTipLocation}
          link={link}
        />
      )
    );
  }

  function renderLayers() {
    if (layersOff) return [];
    const mapLayers = [];
    layers.forEach((layer, i) => {
      if (layer.on) {
        if (viewportState.zoom < 9) {
          const layerProps = {
            data: data.partner[layerHashMap[layer.name]].troublePoints,
            pickable: true,
            getPosition: (d) => [d.geo.long, d.geo.lat],
            radiusMinPixels: 1,
            radiusMaxPixels: 10,
            getRadius: (d) => 4,
            getFillColor: pointStyles[layer.name].fill,
            opacity: 1,
            fp64: true,
          };
          const newLayer = new ClusterLayer({
            ...layerProps,
            sizeScale: 60,
            id: `icon-cluster-${i}`,
          });

          const layerPropsBehind = {
            data: data.partner[layerHashMap[layer.name]].troublePoints,
            pickable: true,
            getPosition: (d) => [d.geo.long, d.geo.lat],
            radiusMinPixels: 1,
            radiusMaxPixels: 60,
            getRadius: (d) => Math.sqrt(d) + 6,
            getFillColor: pointStyles[layer.name].fillbehind,
            opacity: 0.1,
            behind: true,
            fp64: true,
          };
          const newLayerBehind = new ClusterLayer({
            ...layerPropsBehind,
            sizeScale: 60,
            id: `icon-cluster-behind-${i}`,
          });

          mapLayers.push(newLayer, newLayerBehind);
        } else {
          const newLayer = new ScatterplotLayer({
            id: `scatterplot-layer-${layer.name.replace(" ", "")}-${ISPid}`,
            data: data.partner[layerHashMap[layer.name]].troublePoints,
            pickable: true,
            opacity: 0.8,
            stroked: true,
            filled: true,
            radiusScale: 2,
            radiusUnits: "pixels",
            radiusMinPixels: 1,
            radiusMaxPixels: 10,
            fp64: true,
            lineWidthMinPixels: pointStyles[layer.name].lineWidth,
            getPosition: (d) => [d.geo.long, d.geo.lat],
            getRadius: (d) => 2.5,
            getFillColor: (d) =>
              d.selected && messageClicked.id != -1
                ? [0, 0, 0]
                : pointStyles[layer.name].filled
                ? pointStyles[layer.name].fill
                : [0, 0, 0, 0],
            getLineColor: (d) =>
              d.selected ? [0, 0, 0] : pointStyles[layer.name].line,
            transitions: {
              getRadius: {
                type: "spring",
                stiffness: 0.01,
                damping: 0.15,
                enter: (value) => [25], // shrink from size 25
              },
            },
            updateTriggers: {
              getFillColor: [messageClicked.id],
              getRadius: [messageClicked.id],
              getLineColor: [messageClicked.id],
            },
            onClick: (info) => {
              if (messageClicked.id === info.object.id) {
                closeIDCopy();
                return;
              }

              setToolTipLocation({ x: info.x, y: info.y });
              setLayerName(layer.name);
              const deployment = info.object
                ? info.object.deployment
                  ? info.object.deployment
                  : ""
                : "";
              let idCluster = info.object
                ? info.object.idCluster
                  ? info.object.idCluster
                  : ""
                : "";
              const time = info.object
                ? info.object.time
                  ? info.object.time
                  : ""
                : "";
              const index = info.index;
              idCluster =
                idCluster === messageClicked.idCluster ? -1 : idCluster;
              selectPointData(
                data.partner[layerHashMap[layer.name]].troublePoints,
                index
              );
              setHoveredObjectClicked(info.index !== -1 && idCluster !== -1);
              setMessageClicked({ idCluster: idCluster });
            },
          });
          mapLayers.push(newLayer);
        }
      }
    });

    return mapLayers;
  }
  const nowLayers = renderLayers();
  const expandedStyles = mapExpanded
    ? {
        position: "fixed",
        height: "100vh",
        width: "100vw",
        top: "0px",
        left: "0px",
        zIndex: "3000",
      }
    : {};

  return (
    <div className="monitor-dash" style={expandedStyles}>
      <div className="monitor-map-controls">
        <div className="monitor-map-top-controls">
          <ExpandLeft handleExpansion={handleShowTime} />
          {showTime ? (
            <TitleUpdate
              title={t("monitor.map.monitor")}
              time={timeFormatted}
            />
          ) : null}
        </div>
        <div className="monitor-map-bottom-controls">
          <div className="monitor-map-bottom-left-controls">
            <Layers />
          </div>
          <div className="monitor-map-bottom-right-controls">
            <ExpandMap
              expanded={mapExpanded}
              handleExpandMap={expandCollapseMap}
            />
            <ZoomInOutHome
              zoomInAction={handleZoomIn}
              zoomOutAction={handleZoomOut}
              handleHomeClick={handleResetViewport}
              expanded={mapExpanded}
            />
          </div>
        </div>
      </div>
      {renderOnClick()}
      <ReactMapGL
        className="mapbox-map"
        scrollZoom={true}
        doubleClickZoom={true}
        dragRotate={false}
        {...viewportState}
        onViewportChange={(viewport) => {
          handleSetViewPort(viewport);
        }}
        mapStyle={
          "https://tiles.stadiamaps.com/styles/alidade_smooth_dark.json"
        }
      >
        <DeckCont viewport={{ ...viewportState }} layers={nowLayers} />
        <div style={{ position: "absolute", right: 0 }}></div>
      </ReactMapGL>
    </div>
  );
}

const mapStateToProps = (state) => ({
  layers: state.monitorMap.layers,
  ISPid: state.me.selectedPartner.id,
  mapTime: state.monitor.mapDataTime,
});

export default connect(mapStateToProps, undefined)(MapComp);
