import React, { useCallback } from "react";
import { SlButton } from "../../Atoms/Button/Button";
import { SlIcon } from "../../Atoms/Icon/Icon";
import HamburgerIcon from "../../../../../assets/svg/icon-menu.svg";
import { SlItem } from "../../Atoms/Item/Item";
import { SlDropdown } from "../../Molecules/Dropdown/Dropdown";
import { useLocation } from "react-router-dom";
import { useNavigation } from "../../../../../hooks/useNavigation";
import FormattedMessage from "../../../../../translations/FormattedMessage";

const Navigation = () => {
  const { items, comingSoonItems } = useNavigation();
  const currentLocation = useLocation();

  const isCurrentRoute = useCallback(
    (pathString) => {
      return currentLocation.pathname.toLowerCase().includes(pathString);
    },
    [currentLocation]
  );

  const getIconForElement = (navigationItem) => {
    const svgFile = isCurrentRoute(navigationItem.route)
      ? navigationItem.iconCurrent
      : navigationItem.icon;
    return <SlIcon svgFile={svgFile} />;
  };

  return (
    <SlDropdown
      size={"auto"}
      trigger={
        <SlButton round>
          <SlIcon width={30} height={30} svgFile={HamburgerIcon} />
        </SlButton>
      }
    >
      {items.map((item) => (
        <SlItem
          to={item.route}
          key={item.labelId}
          className="sl-navigation-item"
          emphasis={isCurrentRoute(item.route)}
          start={getIconForElement(item)}
        >
          <FormattedMessage id={item.labelId} />
        </SlItem>
      ))}

      <SlItem inactive className="sl-disabled">
        <FormattedMessage id="navigation.comingSoon" />
      </SlItem>

      {comingSoonItems.map((item) => (
        <SlItem
          inactive
          key={item.labelId}
          className="sl-disabled sl-navigation-item--coming-soon"
          start={getIconForElement(item)}
        >
          <FormattedMessage id={item.labelId} />
        </SlItem>
      ))}
    </SlDropdown>
  );
};

export default Navigation;
