import React, { useCallback } from "react";
import PropTypes from "prop-types";
import InputText from "../../Atoms/InputText";
import IconAndTextButton from "../../Atoms/IconAndTextButton";
import { SlIcon } from "../../Atoms/Icon/Icon";
import { SlButton } from "../../Atoms/Button/Button";
import { Form, Field, Formik } from "formik";
import cx from "classnames";
import "./inputForm.scss";
import { validateEmail } from "./helpers";
import { SlText } from "../../Atoms/Text/Text";
import { useTranslation } from "react-i18next";

const InputForm = ({
  iconPath,
  placeholderText,
  submitTitle,
  description,
  submitIconPath,
  deleteList,
  onSubmit,
  isEditingDisabled,
}) => {
  const { t } = useTranslation();
  const isDuplicatedEmail = useCallback(
    (email) => {
      return Boolean(
        deleteList.find((e) => e.toLowerCase() === email.toLowerCase())
      );
    },
    [deleteList]
  );

  const getFormClassNames = (errors) => {
    const hasErrors = Boolean(Object.keys(errors)?.length);
    return cx("sl-input-form", {
      "sl-input-form--with-errors": hasErrors,
    });
  };

  const validateForm = useCallback(
    (values) => {
      const errors = {};
      if (!values.email) {
        errors.email = t(
          "admin.emailNotifications.validationMessages.requiredEmail"
        );
      } else if (!validateEmail(values.email)) {
        errors.email = t(
          "admin.emailNotifications.validationMessages.incorrectEmail"
        );
      } else if (isDuplicatedEmail(values.email)) {
        errors.email = t(
          "admin.emailNotifications.validationMessages.duplicatedEmail"
        );
      }
      return errors;
    },
    [deleteList]
  );

  const getInitialFormValues = useCallback(() => {
    return { email: "" };
  }, []);

  return (
    <div className="sl-input-form-wrapper">
      <SlText subtitle2m>{description}</SlText>
      <Formik
        isInitialValid
        validateOnChange
        initialValues={getInitialFormValues()}
        validate={validateForm}
        onSubmit={({ email }, formikHelpers) => {
          onSubmit(email);
          formikHelpers.resetForm();
        }}
      >
        {({ handleSubmit, values, errors }) => (
          <Form className={getFormClassNames(errors)}>
            <SlIcon svgFile={iconPath} />
            <div className="sl-input-form-input">
              <Field
                component={InputText}
                placeholderText={placeholderText}
                name="email"
                type="text"
                value={values.email}
                isValid={!errors.email}
                disabled={isEditingDisabled}
                hasError={Boolean(errors.email)}
              />
              {errors && errors.email && (
                <span className="sl-input-form-error">{errors.email}</span>
              )}
            </div>
            <SlButton
              disabled={isEditingDisabled}
              start={<SlIcon svgFile={submitIconPath} />}
              backgroundOpacity={0.1}
              backgroundOpacityHighlighted={0.2}
            >
              <SlText p7>{submitTitle}</SlText>
            </SlButton>
          </Form>
        )}
      </Formik>
    </div>
  );
};

InputForm.propTypes = {
  description: PropTypes.string.isRequired,
  Icon: PropTypes.element,
  placeHolderText: PropTypes.string,
  submitTitle: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  deleteList: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default InputForm;
