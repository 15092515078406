import { maxBy, minBy, isNull } from "lodash";

const getMinOrMax = (markers, minOrMax, latOrLng) => {
  if (minOrMax === "max") {
    return Number(maxBy(markers, (value) => value[latOrLng])[latOrLng]);
  } else {
    return Number(minBy(markers, (value) => value[latOrLng])[latOrLng]);
  }
};

export const getBounds = (markers) => {
  const maxLat = getMinOrMax(markers, "max", "lat");
  const minLat = getMinOrMax(markers, "min", "lat");
  const maxLng = getMinOrMax(markers, "max", "long");
  const minLng = getMinOrMax(markers, "min", "long");
  const southWest = [minLng, minLat];
  const northEast = [maxLng, maxLat];
  return [southWest, northEast];
};

export const clusterAlertMapMarkers = (locations) => {
  if (!locations.length) return locations;
  if (locations[0].idCluster !== undefined) return locations;
  let res = [];
  let idType = "";
  if (locations[0].id) {
    idType = "id";
  }
  if (locations[0].locationId) {
    idType = "locationId";
  }
  locations
    .sort((a, b) => (a.lat > b.lat ? 1 : -1))
    .filter((el) => !isNull(el.lat))
    .forEach((currEl, index, elements) => {
      let nextEl = elements[index + 1];
      currEl.idCluster = currEl.idCluster
        ? [...currEl.idCluster, currEl[idType]]
        : [currEl[idType]];
      if (
        currEl.lat !== nextEl?.lat ||
        currEl.long !== nextEl?.long ||
        nextEl === undefined
      ) {
        delete currEl[idType];
        currEl.idClusterType = idType;
        res.push(currEl);
      } else {
        nextEl.idCluster = [...currEl.idCluster];
      }
    });
  return res;
};
